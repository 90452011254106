import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VFileInput } from 'vuetify/lib/components/VFileInput';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,{attrs:{"text":""},on:{"click":function($event){return _vm.openDialog()}}},[_c(VIcon,{attrs:{"color":"gray","left":"","small":""}},[_vm._v("mdi-upload-multiple")]),_vm._v(" EXCEL ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('reports.titles.checkBurdens'))+" "),_c(VSpacer)],1),_c(VCardText,[_c(VForm,{ref:"form",model:{value:(_vm.valid),callback:function ($$v) {_vm.valid=$$v},expression:"valid"}},[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VAlert,{staticClass:"mt-2",attrs:{"border":"left","color":"orange","dense":"","text":"","type":"warning","icon":"mdi-alert-outline"}},[_vm._v(" "+_vm._s(_vm.$t("reports.notification.warningCheckBurdens"))+" ")])],1),_c(VCol,{attrs:{"cols":"12"}},[_c(VFileInput,{attrs:{"label":_vm.$t('general.buttons.upload_doc'),"rules":_vm.requireRulesSizeAndType(_vm.file).concat(_vm.required),"append-icon":"mdi-file-excel","color":"secondary","prepend-icon":""},model:{value:(_vm.file),callback:function ($$v) {_vm.file=$$v},expression:"file"}})],1)],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"disabled":_vm.loading,"color":"gray","small":"","text":""},on:{"click":_vm.dialogCheckBurdensClose}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.cancel'))+" ")]),_c(VBtn,{attrs:{"disabled":_vm.loading || !_vm.valid,"loading":_vm.loading,"color":"secondary","small":"","text":""},on:{"click":function($event){return _vm.checkBurdensAction()}}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.confirm'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }