<template>
  <v-container fluid>
    <v-data-table
      :height="(recordsByCompany && recordsByCompany.length > 10) ? '60vh' : '100%'"
      :headers="headers"
      hide-default-header
      :items="recordsByCompany"
      :loading="loading"
      :options.sync="options"
      :footer-props="{
        ...rowsPerPageItemsOptions,
        itemsPerPageText: $t('general.table.itemsPerPageText'),
        pageText: '{0}-{1} ' + $t('general.table.pageText') + ' {2}',
      }"
      :server-items-length="totalFromServer"
      v-model="selectedItems"
      :show-select="showCheckBox"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
    >
      <template v-slot:top>
        <v-row class="d-flex justify-space-between align-center">
          <v-col lg="4" md="4" sm="12" xl="4">
            <v-text-field
              onkeypress="return (event.charCode != 34)"
              v-on:keyup.86="replace"
              v-on:keyup.ctrl.86="replace"
              dense
              filled
              rounded
              color="secondary"
              v-model="search"
              :clearable="true"
              @keypress.enter="searchForText"
              @click:clear="searchAll"
              @click:append="searchForText"
              append-icon="mdi-magnify"
              :label="$t('general.titles.filter')"
              single-line
              hide-details
              :disabled="!!(source && source.token)"
            />
          </v-col>
          <v-col lg="4" md="4" sm="12" xl="4">
            <v-btn
              small
              tile
              :elevation="0"
              color="#d8d8d896"
              :style="!showCheckBox ? 'border-radius: 1.5em' : 'border-radius: 1.5em 0 0 1.5em'"
              @click.native="checkBoxShow"
              class="mr-1"
              :disabled="
                !!(source && source.token) ||
                !(recordsByCompany && recordsByCompany.length > 0)
              "
            >
              <span v-if="!showCheckBox">
                <v-icon small color="#6D6D6D" left
                  >mdi-checkbox-multiple-marked-outline</v-icon
                >
                <span>{{ $t('general.buttons.checksBoxEnable') }}</span>
              </span>
              <span v-else>
                <v-icon small color="#6D6D6D" left
                  >mdi-checkbox-multiple-marked</v-icon
                >
                <span>{{ $t('general.buttons.checksBoxDisable') }}</span>
              </span>
            </v-btn>
            <v-btn
                v-if="showCheckBox"
                small
                tile
                :elevation="0"
                color="#d8d8d896"
                style="border-radius: 0 1.5em 1.5em 0"
                @click.native="multiBarcode"
                :loading="loadingMultiBarcode"
                class="mr-1"
                :disabled="
                !(!invalidSelectedRecords && selectedItems.length > 0)
              "
            >
              <template v-slot:loader>
                <v-progress-circular
                    style="position: absolute; margin-left: -3px; height: 15px"
                    color="secondary accent-4"
                    indeterminate
                    rounded
                />
              </template>
              <v-icon small color="#6D6D6D" left
              >mdi-qrcode</v-icon
              >
              <span>{{ $t('general.buttons.generate') }}</span>
            </v-btn>
          </v-col>
          <v-col lg="4" md="4" sm="8" xl="4">
            <v-toolbar flat color="white">
              <v-spacer></v-spacer>
              <v-badge
                bordered
                color="secondary"
                overlap
                :content="cantFilters"
                left
                :value="cantFilters"
              >
                <v-btn
                  small
                  tile
                  :elevation="0"
                  color="#d8d8d896"
                  style="border-radius: 1.5em 0 0 1.5em"
                  @click="openFilters"
                  class="mr-1"
                  :disabled="!!(source && source.token)"
                >
                  <v-icon small color="#6D6D6D" left>mdi-filter-variant</v-icon>
                  <span style="color: #6d6d6d">{{
                    $t('general.buttons.filter')
                  }}</span>
                </v-btn>
              </v-badge>
              <!--ADD APPOINTMENT *********************************************************************-->
              <template>
                <v-menu
                  :absolute="false"
                  :open-on-hover="false"
                  :close-on-click="true"
                  :close-on-content-click="false"
                  :offset-y="true"
                  v-model="menu"
                >
                  <template v-slot:activator="{ on }">
                    <v-btn
                      small
                      tile
                      :elevation="0"
                      color="#d8d8d896"
                      class="mr-1"
                      :disabled="!!(source && source.token)"
                      @click="menu = !menu"
                    >
                      <v-icon small color="#6D6D6D" left
                        >mdi-cog</v-icon
                      >
                      <span style="color: #6d6d6d">{{ $t('general.buttons.options') }}</span>
                    </v-btn>
                  </template>
                  <v-list class="pa-3">
                    <v-list-item
                      dense
                      class="d-block py-2"
                    >
                      <v-list-item-title class="mb-1">
                        <span style="color: #6d6d6d">{{
                            $t('records.buttoms.export')
                          }}</span>
                      </v-list-item-title>
                      <div class="d-block">
                        <!--        Export PDF-->
                        <ExportPDFComponent :totalFromServer="totalFromServer" :disable="!(recordsByCompany && recordsByCompany.length > 0)" v-on:closeMenu="(menu = $event)" :selected-items="selectedItems" :pageable="defaultPageableDTO" :search="search"/>
                        <!--        Export CSV-->
                        <ExportCSVComponent :totalFromServer="totalFromServer" :disable="!(recordsByCompany && recordsByCompany.length > 0)" v-on:closeMenu="(menu = $event)" :selected-items="selectedItems" :pageable="defaultPageableDTO" :search="search"/>
                      </div>
                    </v-list-item>
                    <v-list-item
                      dense
                      class="d-block py-2"
                    >
                      <v-list-item-title>
                        <span style="color: #6d6d6d">{{
                            $t('records.buttoms.import')
                          }}</span>
                      </v-list-item-title>
                      <div class="d-block">
                        <!--        Import CSV-->
                        <DownloadCSVTemplateComponent v-on:closeMenu="(menu = $event)" />
                        <!--        Template CSV-->
                        <ImportCSVComponent v-on:closeMenu="(menu = $event)" />
                      </div>
                    </v-list-item>
                    <v-list-item
                      dense
                      class="d-block py-2"
                      v-if='integrations.ABRAPA'
                    >
                      <v-list-item-title>
                        <span style="color: #6d6d6d">{{
                            $t('records.buttoms.check_burdens')
                          }}</span>
                      </v-list-item-title>
                      <div class="d-block">
                        <!--        Import CSV-->
                        <CheckBurdentsComponent v-on:closeMenu="(menu = $event)" />
                      </div>
                    </v-list-item>

                  </v-list>
                </v-menu>
                <v-btn
                  small
                  tile
                  :elevation="0"
                  color="#d8d8d896"
                  style="border-radius: 0 1.5em 1.5em 0"
                  :loading="request || loadingProcess"
                  @click="addRecords"
                  v-if="check([{ domain: 'Record', permisions: ['Write'] }])"
                  :disabled="request || loadingProcess"
                >
                  <v-icon small color="#6D6D6D" left>mdi-plus</v-icon>
                  <span style="color: #6d6d6d">{{
                    $t('general.buttons.new')
                  }}</span>
                  <template v-slot:loader>
                    <v-progress-circular indeterminate size="12" width="2" />
                  </template>
                </v-btn>
              </template>
              <!--END ADD APPOINTMENT *****************************************************************-->
            </v-toolbar>
          </v-col>
        </v-row>
        <!--FILTERS TOOLBAR *********************************************************************-->
        <v-toolbar
          class="mb-3"
          flat
          style="background-color: #f4f2f2; border-radius: 0.3em"
          v-if="filtersChips && filtersChips.length > 0"
        >
          <v-row style="display: contents">
            <v-col cols="12">
              <v-chip-group active-class="neuter--text" show-arrows>
                <v-chip
                  :disabled="
                    !!(source && source.token) || !!(search && search !== '')
                  "
                  small
                  color="#FFFFFF"
                  close
                  v-for="chip in filtersChips"
                  :key="chip.value"
                  @click:close="cleanFilter(chip)"
                >
                  <v-avatar left>
                    <v-icon small>mdi-filter-variant</v-icon>
                  </v-avatar>
                  {{ chip.name }}
                </v-chip>
              </v-chip-group>
            </v-col>
          </v-row>
        </v-toolbar>
        <!--FILTERS TOOLBAR *********************************************************************-->
      </template>
      <template v-slot:header="{ props }">
        <thead class="table__head">
          <th v-for="header in props.headers" :style="`max-width: 100%; width: ${header.width}px`"
            class="text-start px-4">
            <span v-if="header.value === 'action'" class="d-flex justify-center">
              {{ $t(header.text) }}
              <UserOptions
                ref="tableExtraColumns"
                :module="'records'"
                :stage="filterRecord.stageId"
                :type="'TABLE'"
              />
            </span>
            <span v-else>
              <span v-if="['record_date_time', 'sent_date_time', 'uniqueIdRepresentation'].includes(header.value)">
                {{ $t(header.text) }}
                <v-btn icon small @click="toggleSort(header.value)">
                  <v-icon small>{{ getSortIcon(header.value) }}</v-icon>
                </v-btn>
              </span>
              <span v-else>
                {{ $t(header.text) }}
              </span>
            </span>
          </th>
        </thead>
      </template>
      <template v-slot:body="{ items }">
        <tr>
          <td :colspan="headers.length" v-if="loading">
            <div class="d-flex justify-center align-center mt-2">
              {{ $t('general.notification.loadData') }}
            </div>
          </td>
          <td
            :colspan="headers.length"
            v-if="!(recordsByCompany && recordsByCompany.length > 0) && !loading"
          >
            <div class="d-flex justify-center align-center mt-2">
              {{ $t('general.ui.not_data_found') }}
            </div>
          </td>
        </tr>

        <tr v-for="item in items" style="height: 50px">
          <td v-if="showCheckBox" class="d-flex justify-center align-content-center align-items-center">
            <v-checkbox
                v-model="selectedItems"
                :value="item"
                class="ma-0 pt-3"
                hide-details
                color="secondary"
            />
          </td>
          <td v-for="header in headers" class="px-4">
            <div v-if="header.value === 'statusLine'">
              <div class="dot-validated" v-if="item.status === 'VALIDATED'">
              </div>
              <div class="dot-pending" v-if="item.status === 'PENDING'">
              </div>
              <div class="dot-validating" v-if="item.status === 'VALIDATING'">
              </div>
              <div class="dot-errored" v-if="item.status === 'ERRORED'">
              </div>
            </div>
            <div v-if="header.value === 'status'">
              <v-chip small v-if="item.status === 'VALIDATED'">{{ $t('records.filters.status.validated') }}</v-chip>
              <v-chip small v-if="item.status === 'PENDING'">{{ $t('records.filters.status.pending') }}</v-chip>
              <v-chip small v-if="item.status === 'VALIDATING'">{{ $t('records.filters.status.validating') }}</v-chip>
              <v-chip small v-if="item.status === 'ERRORED'">{{ $t('records.filters.status.errored') }}</v-chip>
            </div>
            <div v-if="header.value === 'uniqueIdRepresentation'">
              <UniqueIdRepresentationComponent :unique-id="item.uniqueIdRepresentation" />
            </div>
            <div v-if="header.value === 'sdi_origin'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-chip v-on="on" small>{{
                    (item.sdi_origin.primaryLocation.name +
                      ' | ' +
                      translate(item.sdi_origin.control_label))
                      | truncate(30)
                  }}</v-chip>
                </template>
                <span style="font-size: 12px; color: #f5f5f5"
                  >{{
                    item.sdi_origin.primaryLocation.name +
                    ' | ' +
                    translate(item.sdi_origin.control_label)
                  }}
                </span>
              </v-tooltip>
            </div>
            <div v-else-if="header.value === 'sdi_destination'">
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-chip v-on="on" small>{{
                    (item.sdi_destination.primaryLocation.name +
                      ' | ' +
                      translate(item.sdi_destination.control_label))
                      | truncate(30)
                  }}</v-chip>
                </template>
                <span style="font-size: 12px; color: #f5f5f5">
                  {{
                    item.sdi_destination.primaryLocation.name +
                    ' | ' +
                    translate(item.sdi_destination.control_label)
                  }}
                </span>
              </v-tooltip>
            </div>
            <div v-else-if="header.value === 'record_date_time'" style='width: 150px !important;'>
              <span>{{ getDateFormat(item.record_date_time) || '-' }}</span>
            </div>
            <div v-else-if="header.value === 'sent_date_time'" style='width: 150px !important;'>
              <span>{{ getDateFormat(item.sent_date_time) || '-' }}</span>
            </div>
            <div v-else-if="header.value === 'process_name'">
              <v-chip class="ma-1" small>{{
                translate(item.process.language_key)
              }}</v-chip>
            </div>
            <div v-else-if="header.value === 'person_in_charge_name'">
              <v-chip class="ma-1" small>{{
                item.person_in_charge.name
              }}</v-chip>
            </div>
            <div
              v-else-if="header.value === 'action'"
              class="d-flex justify-center align-center"
            >
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    :disabled="!(allProcess.length > 0)"
                    v-on="on"
                    color="neuter"
                    small
                    class="mr-2"
                    @click="getDetails(item)"
                  >
                    mdi-eye-outline
                  </v-icon>
                </template>
                <span>
                  {{ $t('general.buttons.show') }}
                  <span style="font-size: 12px; color: #aaaaaa">
                    {{ $t('menu.records') }}
                  </span>
                </span>
              </v-tooltip>
              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    :disabled="!(allProcess.length > 0) || ['ERRORED'].includes(item.status)"
                    v-on="on"
                    color="neuter"
                    small
                    class="mr-2"
                    @click="singleBarcode(item)"
                  >
                    mdi-qrcode
                  </v-icon>
                </template>
                <span>
                  {{ $t('general.buttons.qrcode') }}
                  <span style="font-size: 12px; color: #aaaaaa">
                    {{ $t('menu.records') }}
                  </span>
                </span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    :disabled="!(allProcess.length > 0) || ['VALIDATING'].includes(item.status)"
                    v-on="on"
                    v-if="
                      checkAdmin() ||
                      (profile.id === item.person_in_charge.id &&
                        check([{ domain: 'Record', permisions: ['Update'] }]))
                    "
                    color="neuter"
                    class="mr-2"
                    small
                    @click="editItem(item)"
                  >
                    mdi-square-edit-outline
                  </v-icon>
                </template>
                <span>
                  {{ $t('general.buttons.edit') }}
                  <span style="font-size: 12px; color: #aaaaaa">
                    {{ $t('menu.records') }}
                  </span>
                </span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-icon
                    :disabled="!(allProcess.length > 0)"
                    v-on="on"
                    v-if="
                      checkAdmin() ||
                      (profile.id === item.person_in_charge.id &&
                        check([{ domain: 'Record', permisions: ['Delete'] }]))
                    "
                    color="neuter"
                    small
                    @click="deleteItemDialog(item)"
                  >
                    mdi-trash-can-outline
                  </v-icon>
                </template>
                <span>
                  {{ $t('general.buttons.delete') }}
                  <span style="font-size: 12px; color: #aaaaaa">
                    {{ $t('menu.records') }}
                  </span>
                </span>
              </v-tooltip>

              <v-tooltip bottom>
                <template v-slot:activator="{ on }">
                  <v-menu
                    bottom
                    left
                    v-model='moreOptions[item.id]'
                  >
                    <template v-slot:activator='{ on:menu }'>
                      <v-icon
                        v-on='menu'
                        class='mx-1'
                        :disabled="!(allProcess.length > 0)"
                        v-if="
                              checkAdmin() ||
                              (check([{ domain: 'RecordEvidence', permisions: ['Update'] }]))
                            "
                        color="neuter"
                        size='20'
                        @click='$set(moreOptions, item.id, !moreOptions[item.id])'
                      >
                        mdi-dots-horizontal
                      </v-icon>
                    </template>
                    <v-list elevation='0'>
                      <v-list-item>
                        <!--  DIALOG EVIDENCES  -->
                        <EvidenceDialogComponent @close:options="($set(moreOptions, item.id, $event))" :record='item' />
                      </v-list-item>
                      <v-list-item v-if="item.status === 'ERRORED'">
                        <!--  DOWNLOAD RECORD ERRORS  -->
                        <DownloadErrorsComponent @close:options="($set(moreOptions, item.id, $event))" :item="item" />
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </template>
                <span>
                  {{ $t('general.buttons.options') }}
                  <span style="font-size: 12px; color: #aaaaaa">
                    {{ $t('menu.records') }}
                  </span>
                </span>
              </v-tooltip>
            </div>
            <div v-for="column in item.extraColumns">
              <div v-if="header.value === translate(column.language_key)">
                <v-tooltip max-width="350" top>
                  <template v-slot:activator="{ on }">
                    <span v-on="on">{{
                      column.recordValues | truncate(15)
                    }}</span>
                  </template>
                  <span style="font-size: 12px; color: #f5f5f5"
                    >{{ column.recordValues }}
                  </span>
                </v-tooltip>
              </div>
            </div>
          </td>
        </tr>
      </template>
    </v-data-table>

    <!-- Loading Snackbar-->
    <v-snackbar v-model="request" :timeout="-1">
      <span>
        {{ message }}
        <v-progress-circular class="ml-2"
                             v-if="source"
                             indeterminate
                             size="20"
                             width="4"
        />
      </span>
      <v-btn v-if="source" color="red" text @click="cancelRequest">
        <v-icon left small color="red"> mdi-hand-back-left </v-icon>
        {{ $t('general.buttons.cancel') }}
      </v-btn>
      <v-btn v-else color="secondary" text @click="refreshRequest">
        <v-icon left small color="secondary"> mdi-reload </v-icon>
        {{ $t('general.buttons.refresh') }}
      </v-btn>
    </v-snackbar>

    <!-- Dialog Delete-->
    <v-row justify="center">
      <v-dialog v-model="showDialog" persistent width="600px">
        <v-card>
          <v-card-title class="headline">{{ title }}</v-card-title>
          <v-card-text>{{ dialogText }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small text color="gray" @click="dialogClose">
              {{ $t('general.buttons.cancel') }}
            </v-btn>
            <v-btn small text color="secondary" @click="deleteItem">
              {{ $t('general.buttons.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Dialog Check Burdens-->
    <v-row justify="center">
      <v-dialog v-model="checkBurdensFlag" persistent width="600px">
        <v-card>
          <v-card-title class="headline">{{ $t('general.titles.information')  }}</v-card-title>
          <v-card-text v-if='checkBurdensErrorData.allFail'>{{ $t('general.titles.burdensCheck') + " " +checkBurdensErrorData.allFail.toString() }}</v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn small text color="secondary" @click="closeCheckBurdensDialog">
              {{ $t('general.buttons.confirm') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>

    <!-- Dialog MORE DETAILS -->
    <v-row justify="center">
      <v-dialog persistent v-model="dialogDetails" max-width="800px" scrollable>
        <v-card light color="white">
          <v-card-title class="d-flex justify-space-between px-6"
                        :class="[
                          { 'border-errored__show' : recordSelected.status === 'ERRORED' },
                          { 'border-validating__show' : recordSelected.status === 'VALIDATING' },
                          { 'border-pending__show' : recordSelected.status === 'PENDING' }
                        ]"
          >
            <v-toolbar fixed flat>
              <span
                class="headline mr-4"
                style="
                  font-family: 'Avenir', Helvetica, Arial, sans-serif !important;
                  font-weight: bold !important;
                  color: rgba(35, 35, 35, 0.96) !important;
                ">{{ $t('general.titles.details') }}</span>
              <p class="d-flex justify-space-between align-center align-content-center ma-0 mx-2 px-2 py-1 chip-validating" v-if="recordSelected.status === 'VALIDATING'">
                {{ $t('records.filters.status.validating') }}
              </p>
              <p class="d-flex justify-space-between align-center align-content-center ma-0 mx-2 px-2 py-1 chip-pending" v-if="recordSelected.status === 'PENDING'">
                {{ $t('records.filters.status.pending') }}
              </p>
              <p class="d-flex justify-space-between align-center align-content-center ma-0 mx-2 px-2 py-1 chip-errored" v-if="recordSelected.status === 'ERRORED'">
                {{ $t('records.filters.status.errored') }}
              </p>
            </v-toolbar>

            <span class='pr-6' v-if='logRecordSelected.blockchainSave && logRecordSelected.blockchainHash'>
              <v-card-subtitle>
                <a class='lot__chip my-1 py-1 d-flex justify-start' target='_blank'
                   style="font-family: 'Avenir', Helvetica, Arial, sans-serif !important; font-size: 13px !important;"
                   :href=logRecordSelected.url>
                <strong style="color: rgba(35, 35, 35, 0.96) !important;">Blockchain Hash:&nbsp </strong> {{ logRecordSelected.blockchainHash | truncate(10) }}
                </a>
              </v-card-subtitle>
            </span>
          </v-card-title>
          <v-divider />
          <v-card-text>
            <v-container>
              <v-row v-if="dialogDetails">
                <div class="d-flex flex-wrap">
                  <v-card elevation="0" width="100%" class="my-2">
                    <v-card-title
                      class="pb-1 pt-1"
                      style="background-color: rgba(3, 172, 140, 0.21)"
                    >
                       <span style="color: #33a387; font-size: 18px">{{
                           $t('records.label.titles.registryData')
                         }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text
                      style="
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        padding-top: 0 !important;
                      "
                    >
                      <v-card elevation="0" width="100%" class="mb-3">
                        <v-card-text
                          style="
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                            padding-top: 0 !important;
                          "
                        >
                          <div
                            class="d-flex flex-wrap mb-2 pa-3"
                            style="
                              background-color: rgba(3, 172, 140, 0.04);
                              border-radius: 3px;
                            "
                          >
                            <div
                              style="width: 200px; padding: 5px"
                              v-for="(values, i) in headers"
                              :key="i"
                              v-if="!['action', 'status', 'statusLine'].includes(values.value) && i < 8"
                            >
                              <p
                                class="black--text mb-0"
                                style="
                                  font-family: 'Open Sans', 'Helvetica Neue',
                                    Arial, Helvetica, sans-serif;
                                  font-size: 14px;
                                  font-weight: 400;
                                "
                              >
                                {{ values ? $t(values.text) : '' }}:
                              </p>
                              <p
                                class="black--text mb-2"
                                style="
                                  font-family: 'Open Sans', 'Helvetica Neue',
                                    Arial, Helvetica, sans-serif;
                                  font-size: 16px;
                                  font-weight: 600;
                                "
                                v-if="values.value !== 'uniqueIdRepresentation'"
                              >
                                {{ getDetailsMainData(values.value) || '-' }}
                              </p>
                              <UniqueIdRepresentationComponent v-else :unique-id="recordsCompanyById.uniqueIdRepresentation" format-style="raw" styles="font-size: 16px; color: black" :formated="true" />
                            </div>
                            <div
                              style="width: 200px; padding: 5px"
                            >
                              <p
                                class="black--text mb-0"
                                style="
                                  font-family: 'Open Sans', 'Helvetica Neue',
                                    Arial, Helvetica, sans-serif;
                                  font-size: 14px;
                                  font-weight: 400;
                                "
                              >
                                {{ $t('records.fields.registerDate') }}:
                              </p>
                              <p
                                class="black--text mb-2"
                                style="
                                  font-family: 'Open Sans', 'Helvetica Neue',
                                    Arial, Helvetica, sans-serif;
                                  font-size: 16px;
                                  font-weight: 600;
                                "
                              >
                                {{ getDetailsMainData('sent_date_time') || '-' }}
                              </p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                    <v-divider />
                  </v-card>
                  <div
                    style="width: 250px"
                    v-if="existStructures"
                    v-for="item in getStructures"
                    :key="item.id"
                  >
                    <div
                      style="
                        background-color: rgba(3, 172, 140, 0.02);
                        border-radius: 3px;
                      "
                      class="ma-2 pa-2"
                    >
                      <p
                        class="black--text mb-0"
                        style="
                          font-family: 'Open Sans', 'Helvetica Neue', Arial,
                            Helvetica, sans-serif;
                          font-size: 14px;
                          font-weight: 400;
                        "
                      >
                        {{ translate(item.structure.language_key, true) }}:
                      </p>
                      <p
                        class="black--text mb-0"
                        style="
                          font-family: 'Open Sans', 'Helvetica Neue', Arial,
                            Helvetica, sans-serif;
                          font-size: 16px;
                          font-weight: 600;
                        "
                        v-if="
                          item.recordStructureValue.record_value.length > 0
                        "
                      >
                        <span v-for="structureName in item.recordStructureValue.record_value">
                          {{ getValue(item.structure, structureName) }}
                        </span>
                      </p>
                      <p v-else
                         class="black--text mb-0"
                         style="
                          font-family: 'Open Sans', 'Helvetica Neue', Arial,
                            Helvetica, sans-serif;
                          font-size: 16px;
                          font-weight: 600;
                        ">-</p>
                    </div>
                  </div>

                  <v-card
                    elevation="0"
                    width="100%"
                    class="my-2"
                    v-for="(group, index) in getGroups"
                    :key="index"
                    v-if="existGroups"
                  >
                    <v-card-title
                      class="pb-1 pt-1"
                      style="background-color: rgba(3, 172, 140, 0.21)"
                    >
                      <span style="color: #33a387; font-size: 18px">{{
                        translate(group.languageKey, true).toUpperCase()
                      }}</span>
                    </v-card-title>
                    <v-divider></v-divider>
                    <v-card-text
                      style="
                        padding-left: 0 !important;
                        padding-right: 0 !important;
                        padding-top: 0 !important;
                      "
                    >
                      <v-card
                        elevation="0"
                        width="100%"
                        class="mb-3"
                        v-for="row in group.rows"
                        v-bind:key="row.groupRow"
                      >
                        <v-card-text
                          style="
                            padding-left: 0 !important;
                            padding-right: 0 !important;
                            padding-top: 0 !important;
                          "
                        >
                          <div
                            class="d-flex flex-wrap mb-2 pa-3"
                            style="
                              background-color: rgba(3, 172, 140, 0.04);
                              border-radius: 3px;
                            "
                          >
                            <div
                              style="width: 200px"
                              v-for="values in row.values"
                            >
                              <p
                                class="black--text mb-0"
                                style="
                                  font-family: 'Open Sans', 'Helvetica Neue',
                                    Arial, Helvetica, sans-serif;
                                  font-size: 14px;
                                  font-weight: 400;
                                "
                              >
                                {{
                                  values
                                    ? translate(
                                        values.structure.language_key,
                                        true
                                      )
                                    : ''
                                }}:
                              </p>
                              <p v-if="values.recordStructureValue.record_value &&
                                          values.recordStructureValue.record_value.length > 0">
                                <span
                                  class="black--text mb-2"
                                  style="
                                  font-family: 'Open Sans', 'Helvetica Neue',
                                    Arial, Helvetica, sans-serif;
                                  font-size: 16px;
                                  font-weight: 600;
                                "
                                  v-for="(item, index) in values
                                  .recordStructureValue.record_value"
                                  :key="index"

                                >
                                  {{ getValue(values.structure, item) || '-' }}
                                </span>
                              </p>
                              <p
                                class="black--text mb-2"
                                style="
                                  font-family: 'Open Sans', 'Helvetica Neue',
                                    Arial, Helvetica, sans-serif;
                                  font-size: 16px;
                                  font-weight: 600;
                                "
                                v-else
                              >
                                -
                              </p>
                            </div>
                          </div>
                        </v-card-text>
                      </v-card>
                    </v-card-text>
                  </v-card>
                </div>
              </v-row>
            </v-container>
          </v-card-text>
          <v-divider />
          <v-card-actions class="pa-4">
            <v-btn
              v-if="
                recordSelected.evidences && recordSelected.evidences.length > 0
              "
              text
              :loading="loadingEvidences"
              :disabled="loadingEvidences"
              color="blue-grey"
              class="ma-2 white--text"
              small
              @click="getEvidences"
            >
              <v-icon color="secondary"> mdi-download-multiple </v-icon>
            </v-btn>
            <DownloadErrorsComponent v-if="recordSelected.status === 'ERRORED'" :item="recordSelected" :is-icon="true" icon-color="error" />
            <v-spacer />
            <v-btn small text color="secondary" @click="closeDetails">
              {{ $t('general.buttons.close') }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </v-container>
</template>

<script>
  import "jspdf-autotable"
  import _ from "lodash"
  import i18n from "@/plugins/i18n"
  import axios from "axios"
  import moment from "moment"
  import { mapActions, mapGetters } from "vuex"
  import { generalMixin } from "@/mixins/general.mixin"
  import PermisionService from "@/services/PermisionService"
  import DocumentsService from "@/services/DocumentsService"
  import LanguajeService from "@/services/LanguajeService"
  import UserOptions from "@/components/UserOptions/IndexComponent"
  import ExportPDFComponent from "@/module/records/components/ExportPDFComponent"
  import ExportCSVComponent from "@/module/records/components/ExportCSVComponent"
  import ImportCSVComponent from "@/module/records/components/ImportCSVComponent"
  import DownloadCSVTemplateComponent from "@/module/records/components/DownloadCSVTemplateComponent"
  import EvidenceDialogComponent from "@/module/records/components/EvidenceDialogComponent"
  import CheckBurdentsComponent from "@/module/records/components/CheckBurdentsComponent"
  import DownloadErrorsComponent from "@/module/records/components/DownloadErrorsComponent"
  import {getByProperty} from "@/utils";
  import UniqueIdRepresentationComponent from "@/module/records/components/UniqueIdRepresentationComponent.vue";

  const FILTER_VALUES = [
  'company_id',
  'min_punctuation',
  'max_punctuation',
  'surveyStateType',
  'template_names',
  'pageableDTO',
  ' start_record_date_time',
  ' sent_date_time',
  'end_record_date_time',
  'storage_device_types_ids',
  'storage_device_ids',
  'document_authority_ids',
  'document_type_ids',
  'document_description_ids',
  'document_date',
  'expiration_date',
  'storage_devices_ids',
  'storage_device_names',
  'storage_device_names_list',
  'structure',
  'structureIds',
  'sellers_names',
  'contracts_names',
  'volumeB_range',
  'volumeN_range',
  'quebra_range',
  'contracts_dates',
  'withEvidences',
  'withDocuments',
  'record_ids',
  'sellers_date',
  'language',
  'withExternalVisibility'
  ]

  export default {
    name: 'records-table',
    components: {UniqueIdRepresentationComponent, DownloadErrorsComponent, EvidenceDialogComponent, DownloadCSVTemplateComponent, ImportCSVComponent, ExportCSVComponent, ExportPDFComponent, UserOptions, CheckBurdentsComponent },
    mixins: [generalMixin],
    data: () => ({
      // Profile
      profile: null,
      // Filters
      search: '',
      show: false,
      // Load
      loading: true,
      loadingProcess: true,
      // Pagination
      rowsPerPageItemsOptions: {
        itemsPerPageOptions: [10, 20, 50, 100, 200],
      },
      options: {},
      //Excel
      menu: false,
      processFilter: '',
      showDialogExportCSV: false,
      processCSVExport: null,
      loadingCSV: false,
      loadingMultiBarcode: false,
      // report PDF
      processPDFExport: null,
      logoPositionX: 0,
      doc: '',
      evidenceListUrl: [],
      documentListUrl: [],
      indexEvidence: 0,
      indexDocument: 0,
      // config
      orientation: null,
      documentPresent: true,
      evidencePresent: true,
      exportLoading: false,
      showDialogExportPDF: false,
      //General Export
      itemsExport: [
        { title: 'PDF', icon: 'mdi-download-multiple', action: 'PDF' },
        { title: 'CSV', icon: 'mdi-download-multiple', action: 'CSV' },
      ],
      // Record Data Model
      editedIndex: -1,
      // Dialog Delete
      showDialog: false,
      title: 'Información',
      dialogText: 'Añada un texto a este dialogo',
      // Dialog Details
      message: i18n.t('general.notification.loadData'),
      number: null,
      selectedItems: [],
      showCheckBox: false,
      loadingEvidences: false,
      sdtTab: 0,
      structureNames: [],
      defaultPageableDTO: {
        page: 0,
        size: 10,
        sortBy: 'record_date_time',
        direction: 'desc',
      },
      importOptions: {
        process: null,
        language: null,
        file: null
      },
      moreOptions: {},
      integrations: {
        ABRAPA: null
      },
      sortBy: 'record_date_time',
      sortDesc: true,
      columnSorting: {}
    }),
    watch: {
      options: {
        async handler() {
          this.optionsTable = this.options
          await this.searchText()
        },
        deep: true,
      },
    },
    async created() {
      this.type = 'traceability';
      this.$store.commit('ui/SET_FILTERS_SORT', [8,9,11,12,13,14,36,15,16,33,17]);

      if (!localStorage.getItem('config')) {
        this.headers = _.cloneDeep(this.defaultHeaders)
      }

      this.profile = JSON.parse(localStorage.getItem('profile'))

      await this.fetchListAuthorityProcess([this.profile.id, this.$toast]).finally(() => {
        this.loadingProcess = false;
      })

      this.sdtTab = _.cloneDeep(this.filterRecord.stageId)

      this.integrations.ABRAPA = this.$store.getters['integration/IS_PRESENT']('ABRAPA');
    },
    async mounted() {
      this.type = 'records'
      this.$store.commit('ui/FILTERS_ORIGINAL_STATE')
      this.$store.commit('records/SET_ORIGINAL_STATE_FOR_PRINT_RECORDS')
      let filtersRecords = JSON.parse(localStorage.getItem('filtersRecords'))

      if (filtersRecords) {
        this.structureFilter =
          filtersRecords.structures.structureFilter &&
          filtersRecords.structures.structureFilter.length > 0
            ? filtersRecords.structures.structureFilter
            : []
        this.structuresByIds =
          filtersRecords.structures.structuresByIds &&
          filtersRecords.structures.structuresByIds.length > 0
            ? filtersRecords.structures.structuresByIds
            : []

        _.forEach(filtersRecords.structures.structuresByIds, (s) => {
          this.$set(
            this.data,
            s.structure_id,
            s.structures_selected
          )
        })

        this.filters.stringFilter = filtersRecords.filtersObjects.stringFilter && this.filtersObjects.stringFilter !== ''
          ? this.filtersObjects.stringFilter
          : null

        this.locations.origin_locations_filters =
          filtersRecords.origin_locations_filters
            ? filtersRecords.origin_locations_filters
            : getByProperty(this.locations.origin_locations_filters, 'locationId')
        this.locations.destination_locations_filters =
          filtersRecords.destination_locations_filters
            ? filtersRecords.destination_locations_filters
            : getByProperty(this.locations.destination_locations_filters, 'locationId')
        this.filtersObjects = filtersRecords.filtersObjects
          ? filtersRecords.filtersObjects
          : this.filtersObjects
        this.date.filtersRecordDate = filtersRecords.filtersRecordDate
          ? filtersRecords.filtersRecordDate
          : []
        this.date['record_date_time'] = filtersRecords.record_date_time
          ? filtersRecords.record_date_time
          : ''
        this.date.filtersRegisterDate = filtersRecords.filtersRegisterDate
          ? filtersRecords.filtersRegisterDate
          : []
        this.date['sent_date_time'] = filtersRecords.sent_date_time
          ? filtersRecords.sent_date_time
          : ''
        this.date.filtersDate = filtersRecords.filtersDate
          ? filtersRecords.filtersDate
          : []

        this.filters.status = filtersRecords.filtersObjects.record.status &&
        filtersRecords.filtersObjects.record.status.length > 0
            ? filtersRecords.filtersObjects.record.status
            : []

        this.filters.sdi_destination_ids =
          filtersRecords.filtersObjects.sdi_destination_ids &&
          filtersRecords.filtersObjects.sdi_destination_ids.length > 0
            ? getByProperty(filtersRecords.filtersObjects.sdi_destination_ids, 'storageDeviceId')
            : []
        this.filters.sdi_origin_ids =
          filtersRecords.filtersObjects.sdi_origin_ids &&
          filtersRecords.filtersObjects.sdi_origin_ids.length > 0
            ? getByProperty(filtersRecords.filtersObjects.sdi_origin_ids, 'storageDeviceId')
            : []

        this.filterRecord.stageId =
          this.storageDeviceTypesActiveByCompany[0].storage_device_type_id
        this.filtersChips =
          filtersRecords.filtersChips && filtersRecords.filtersChips.length > 0
            ? filtersRecords.filtersChips
            : []
        this.cantFilters =
          filtersRecords.cantFilters && filtersRecords.cantFilters.length > 0
            ? filtersRecords.cantFilters
            : null
      }

      if (this.date.filtersRecordDate) this.dateRangeText()
      if (this.date.filtersRecordRegisterDate) this.deconstructRangeTextForFilters(this.date.filtersRecordRegisterDate, 'sent_date_time', 'filtersRegisterDate')

      // if (
      //   this.locations.origin_locations_filters ||
      //   this.locations.destination_locations_filters ||
      //   this.filtersObjects ||
      //   this.structureFilter
      // ) {
      //   this.closeFilterDialog()
      // }

      this.$root.$on('getResultsPaginatedByCompany', () => {
        this.getRecordsPaginatedByCompany()
      })

      this.$root.$on('setFiltersRecords', () => {
        this.closeFilterDialog()
      })
    },
    computed: {
      invalidSelectedRecords() {
        return _.filter(this.selectedItems, { status: 'ERRORED' }).length > 0
      },
      emptySearch() {
        return !!(this.search && this.search !== '')
      },
      getValue() {
        return (structure, value) => {
          if (Array.isArray(value) && value.length === 0) {
            console.log(value)
            return '-';
          }

          if (value) {
            let dateFormatted = (
              LanguajeService.getKey3(value) + '.000Z'
            ).slice(0, 19);

            switch (structure.structure_type) {
              case 'DATA':
                return moment(dateFormatted).format('DD / MM / YYYY');
              case 'TIME':
                return moment(dateFormatted).format('HH:mm');
              case 'DATATIME':
                return moment(dateFormatted).format('DD / MM / YYYY HH:mm');
              default:
                return LanguajeService.getKey3(value);
            }
          } else {
            return '-';
          }
        }
      },
      dialogDetails: {
        get() {
          return this.$store.getters['records/dialogDetails'];
        },
        set(value) {
          this.$store.commit('records/SET_DIALOG_DETAILS', value);
        }
      },
      type: {
        get() {
          return this.$store.getters['ui/GET_FILTER_TYPE']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTER_TYPE', val)
        },
      },
      drawer: {
        get() {
          return this.$store.getters['ui/GET_DRAWER']
        },
        set(val) {
          this.$store.dispatch('ui/SET_DRAWER', val)
        },
      },
      filters: {
        get() {
          return this.$store.getters['ui/GET_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTERS', val)
        },
      },
      structureFilter: {
        get() {
          return this.$store.getters['ui/GET_STRUCTURE_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_STRUCTURE_FILTERS', val)
        },
      },
      structuresByIds: {
        get() {
          return this.$store.getters['ui/GET_STRUCTURES_BY_IDS']
        },
        set(val) {
          this.$store.commit('ui/STRUCTURES_BY_IDS', val)
        },
      },
      optionsTable: {
        get() {
          return this.$store.getters['ui/GET_OPTIONS_TABLE']
        },
        set(val) {
          this.$store.dispatch('ui/SET_OPTIONS_TABLE', val)
        },
      },
      headers: {
        get() {
          return this.$store.getters['records/headers']
        },
        set(val) {
          this.$store.commit('records/SET_HEADERS', val)
        },
      },
      locations: {
        get() {
          return this.$store.getters['ui/GET_LOCATIONS_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_LOCATIONS_FILTERS', val)
        },
      },
      filterRecord: {
        get() {
          return this.$store.getters['ui/GET_STAGE_ID']
        },
        set(val) {
          this.$store.dispatch('ui/SET_STAGE_ID', { val })
        },
      },
      filtersObjects: {
        get() {
          return this.$store.getters['ui/GET_OBJECTS_TO_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_OBJECTS_TO_FILTERS', val)
        },
      },
      date: {
        get() {
          return this.$store.getters['ui/GET_DATE_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_DATE_FILTERS', val)
        },
      },
      filtersChips: {
        get() {
          return this.$store.getters['ui/GET_FILTERS_CHIPS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_FILTERS_CHIPS', val)
        },
      },
      cantFilters: {
        get() {
          return this.$store.getters['ui/GET_COUNT_FILTERS']
        },
        set(val) {
          this.$store.dispatch('ui/SET_COUNT_FILTERS', val)
        },
      },
      recordSelected: {
        get() {
          return this.$store.getters['records/record']
        },
        set(val) {
          this.$store.commit('records/SET_RECORD', val)
        },
      },
      logRecordSelected: {
        get() {
          return this.$store.getters['records/recordLog']
        },
        set(val) {
          this.$store.commit('records/SET_RECORD_LOG', val)
        },
      },
      checkBurdensFlag: {
        get() {
          return this.$store.getters['records/checkBurdensError']
        },
        set(val) {
          this.$store.commit('records/CHECK_BURDENS', val)
        },
      },
      checkBurdensErrorData: {
        get() {
          return this.$store.getters['records/checkBurdensErrorData']
        },
        set(val) {
          this.$store.commit('records/CHECK_BURDENS_DATA', val)
        },
      },
      source: {
        get() {
          return this.$store.getters['records/source']
        },
        set(val) {
          this.$store.commit('records/SET_SOURCE', val)
        },
      },
      request: {
        get() {
          return this.$store.getters['records/request']
        },
        set(val) {
          this.$store.commit('records/SET_REQUEST', val)
        },
      },
      allProcess: {
        get() {
          return this.$store.getters['proccess/proccess']
        },
        set(val) {
          this.$store.commit('proccess/SET_PROCCESS_LIST', val)
        },
      },
      data: {
        get() {
          return this.$store.getters['ui/GET_DATA']
        },
        set(val) {
          this.$store.dispatch('ui/SET_DATA', val)
        },
      },
      ...mapGetters({
        recordsByCompany: 'records/recordsByCompany',
        recordsByCompanyFiltered: 'records/recordsByCompanyFiltered',
        recordsCompanyById: 'records/recordsCompanyById',
        recordsToExports: 'records/recordsToExports',
        totalFromServer: 'records/recordsByCompanyTotalCount',
        structuresDetails: 'records/structures',
        storages: 'authority/authoritiesStoragesDevices',
        authorities: 'authority/authorities',
        process: 'proccess/proccess',
        locationsList: 'authority/authoritiesLocations',
        //----ui-----------------------------------------
        defaultHeaders: 'records/defaultHeaders',
        filterHeader: 'ui/GET_HEADERS_RECORDS',
      }),
      config: {
        get() {
          return this.$store.getters['general/config']
        },
        set(val) {
          this.$store.commit('general/SET_CONFIG', val)
        },
      },
      storageDeviceTypesActiveByCompany() {
        return _.orderBy(this.$store.getters['storage_type/storageDeviceTypesActiveByCompany'], ['disposition']);
      },
      // Exist structures
      existStructures() {
        return (
          this.recordSelected.recordStructureValuesOrganized.structures &&
          this.recordSelected.recordStructureValuesOrganized.structures.length >
            0
        )
      },
      // Exist groups
      existGroups() {
        return (
          this.recordSelected.recordStructureValuesOrganized.groups &&
          this.recordSelected.recordStructureValuesOrganized.groups.length > 0
        )
      },
      // Get structures
      getStructures() {
        return this.recordSelected.recordStructureValuesOrganized.structures
          ? this.recordSelected.recordStructureValuesOrganized.structures
          : []
      },
      // Get groups
      getGroups() {
        if (this.flagGroups) this.flagGroups = false
        return this.recordSelected.recordStructureValuesOrganized.groups
          ? this.recordSelected.recordStructureValuesOrganized.groups
          : []
      }
    },

    methods: {
      ...mapActions({
        fetchListRecordsByCompanyPaginated:
          'records/fetchListAppointmentsByCompanyPaginated',
        fetchListRecordsBySearch: 'records/fetchListRecordsBySearch',
        findRecordById: 'records/findRecordById',
        findLogRecordById: 'records/findLogByRecordId',
        findRecordsByIds: 'records/findRecordsByIds',
        deleteRecord: 'records/deleteRecord',
        fetchListAuthorityProcess: 'authority/fetchListAuthorityProcess',
      }),
      toggleSort(column) {
        if (this.columnSorting[column]) {
          this.columnSorting[column] = this.columnSorting[column] === 'desc' ? 'asc' : 'desc';
        } else {
          this.columnSorting = {};
          this.columnSorting[column] = this.options.sortDesc.length === 0 || this.options.sortDesc[0]
            ? 'asc'
            : 'desc';
        }
        this.sortBy = column;
        this.sortDesc = this.columnSorting[column] === 'desc';
      },
      getSortIcon(column) {
        if (this.sortBy === column) {
          return this.sortDesc ? 'mdi-sort-descending' : 'mdi-sort-ascending';
        }
        return 'mdi-sort';
      },
      getDetailsMainData(type) {
        switch (type) {
          case 'record_date_time':
          case 'sent_date_time':
            return this.getDateFormat(this.recordsCompanyById[type])
          default:
            return this.recordsCompanyById[type]
        }
      },
      async searchAll() {
        this.options.page = 1
        this.search = null;
        await this.getRecordsPaginatedByCompany()
      },
      async searchForText() {
        this.options.page = 1
        this.$store.commit('ui/FILTERS_ORIGINAL_STATE')
        this.$store.commit('ui/CLEAR_LOCAL_STORAGE')
        await this.searchText()
      },
      async searchText() {
        this.message = i18n.t('general.notification.loadData')
        this.request = true
        const CancelToken = axios.CancelToken
        this.source = CancelToken.source()

        this.loading = true
        this.$emit('isDisable', this.loading)
        const keys = ['pageableDTO']
        let filters = _.pick(this.filters, keys)

        filters.companyId = this.profile.company_id
        filters.withExtraColumns = true
        filters.stringFilter = this.search
        filters.storageDeviceTypeDTO = {
          id: this.filterRecord.stageId,
        }

        const direction =
          this.options.sortDesc.length === 0 || this.options.sortDesc[0]
            ? 'desc'
            : 'asc'

        const sort = this.options.sortBy.length > 0 ? this.options.sortBy[0] : 'record_date_time';

        filters.pageableDTO = {
          page: this.options.page - 1,
          size: this.options.itemsPerPage,
          sortBy: sort,
          direction: direction,
        }

        if (this.search && this.search !== '') {
          console.log('here1')
          await this.getRecordsPaginatedByCompany()

          if (this.$refs.tableExtraColumns)
            await this.$refs.tableExtraColumns[0].cancelDialogConfig();
        } else {
          console.log('here2')
          await this.closeFilterDialog()
        }

        this.loading = false
      },
      checkBoxShow() {
        this.showCheckBox = !this.showCheckBox

        if (!this.showCheckBox) this.selectedItems = []
      },
      cancelRequest() {
        this.source.cancel('cancelRequest')
        this.message = i18n.t('general.notification.operationCanceled')
        this.source = null
      },
      async refreshRequest() {
        await this.getRecordsPaginatedByCompany()
      },
      async openFilters() {
        this.type = 'records'

        const filtersRecords = JSON.parse(localStorage.getItem('filtersRecords'))

        console.log('init >>> set:location:origin', true)
        this.$root.$emit("set:location:origin", {
          locations: filtersRecords.origin_locations_filters,
          storageDevice: filtersRecords.filtersObjects.sdi_origin_ids
        })

        console.log('init >>> set:location:destination', true)
        this.$root.$emit("set:location:destination", {
          locations: filtersRecords.destination_locations_filters,
          storageDevice: filtersRecords.filtersObjects.sdi_destination_ids
        })

        this.drawer = true
      },
      translate(key, uppercase) {
        return LanguajeService.getKey3(key)
      },
      // Search all records by "search" criteria
      searchOnAll(items) {
        return items.filter(item => {
          return Object.values(item)
            .join(',')
            .toUpperCase()
            .includes(this.search.toUpperCase())
        })
      },
      // Get date format
      getDateFormat(dateString) {
        if (dateString)
          return moment(new Date(dateString)).format(this.date.dateFormat)

        return null;
      },
      // Check permission
      check(permissions) {
        return PermisionService.check(permissions)
      },
      // Check permission Admin
      checkAdmin() {
        return PermisionService.checkAdmin()
      },
      // Add Record
      addRecords() {
        localStorage.setItem('records_editOrSave', -1)
        this.$store.commit('records/SET_ORIGINAL_STATE_RECORD')
        this.$router.push('/recordsAdd')
      },
      // Edit Record
      async editItem(item) {
        this.loading = true
        this.editedIndex = item.id
        await this.findRecordById([item.id, this.$toast]).finally(() => {
          this.loading = false
        })
        localStorage.setItem('records_editOrSave', this.editedIndex)
        await this.$router.push({ path: '/recordsAdd' })
      },

      // Get records from getters
      getRecordsGetters(search) {
        let items = this.recordsByCompany
        if (search) {
          items = this.searchOnAll(items)
        }
        return items
      },
      // Show Delete dialog for delete Record
      deleteItemDialog(item) {
        this.deletingItem = item
        this.dialogShow({
          title: i18n.t('general.titles.alert'),
          dialogText: i18n.t('general.notification.deleteItems'),
        })
      },
      // Close Delete dialog for delete Record
      dialogClose() {
        this.showDialog = false
      },
      // Dialog data
      dialogShow(params) {
        this.title = params.title
        this.dialogText = params.dialogText
        this.showDialog = true
      },
      // Delete Action
      deleteItem() {
        this.dialogClose()
        this.deleteRecord([
          this.deletingItem.id,
          this.$toast
        ]).finally(() => {
          this.getRecordsPaginatedByCompany()
        })
      },
      // Confirm Check Burdens
      closeCheckBurdensDialog() {
        this.checkBurdensFlag = false;
        this.checkBurdensErrorData = [];
      },
      // Get Evidences to download
      getEvidences() {
        this.loadingEvidences = true
        try {
          DocumentsService.getEvidencas(this.recordSelected.id).then(
            response => {
              const FileSaver = require('file-saver')
              const blob = new Blob([response.data], {
                type: 'application/zip',
              })
              FileSaver.saveAs(blob, 'evidences.zip')
            }
          )
        } catch (error) {
          this.$toast.warning(i18n.t('records.notification.evidencesCero'), {
            queueable: true,
            color: '#777',
          })
        }
        this.loadingEvidences = false
      },
      // Get data more details dialog
      async getDetails(item) {
        this.loading = true
        const promises = []
        // add here a list of promises;
        promises.push(this.findRecordById([item.id, this.$toast]))
        promises.push(this.findLogRecordById([item.id, this.$toast]))

        Promise.all(promises).finally(() => {
          this.loading = false
          this.dialogDetails = true
        })
      },
      // Close Details (Structures) dialog
      closeDetails() {
        this.dialogDetails = false
        this.$store.commit('records/RESTORE_RECORD_LOG')
      },

      async singleBarcode(item) {
        await this.findRecordById([item.id, this.$toast])
        await this.$router.push({ path: '/configRecordLabel' })
      },

      async multiBarcode() {
        this.loadingMultiBarcode = true
        const ids = this.getByProperty(this.selectedItems, 'id')
        await this.findRecordsByIds([ids, this.$toast]).finally(() => {
          setTimeout(()=> {
            this.loadingMultiBarcode = false
          }, 2000)
        })
        await this.$router.push({ path: '/configRecordLabel'})
      },

      //Report PDF
      dialogExportPDFClose() {
        this.orientation = null
        this.processPDFExport = null
        this.documentPresent = true
        this.evidencePresent = true
        this.showDialogExportPDF = false
        this.exportLoading = false
        this.evidenceListUrl = []
        this.documentListUrl = []
        this.indexEvidence = 0
        this.indexDocument = 0
      },

      exportDialogPDF() {
        this.showDialogExportPDF = true
      },

      // FILTERS
      cleanFilter(chip) {
        let tag = chip.value
        let filtersRecords = JSON.parse(localStorage.getItem('filtersRecords'))
        if (this.filters[tag]) {
          if (this.filtersObjects[tag]) {
            filtersRecords.filtersObjects[tag] = []
            this.filtersObjects[tag] = []
            this.filters[tag] = []
          }

          if (tag === 'status') {
            this.$set(this.filtersObjects, 'record', {
              status: []
            })
            this.$set(filtersRecords.filtersObjects, 'record', {
              status: []
            })
            this.filters.status = []
          }
          if (tag === 'sdi_origin_ids') {
            this.locations.origin_locations_filters = []
            filtersRecords.origin_locations_filters = []
            this.$root.$emit("set:location:origin", {
              locations: [],
              storageDevice: []
            })
          } else if (tag === 'sdi_destination_ids') {
            this.locations.destination_locations_filters = []
            filtersRecords.destination_locations_filters = []
            this.$root.$emit("set:location:destination", {
              locations: [],
              storageDevice: []
            })
          }
          if (
            tag === 'end_record_date_time' ||
            tag === 'start_record_date_time'
          ) {
            this.date.filtersRecordDate = []
            filtersRecords.filtersRecordDate = []
            this.date.filtersDate = []
            filtersRecords.filtersDate = []
            this.filters['end_record_date_time'] = []
            this.filters['start_record_date_time'] = null
            this.filtersObjects.start_record_date_time = null
            filtersRecords.start_record_date_time = null
          }
          if (
            tag === 'sent_date_time'
          ) {
            this.date.filtersRecordRegisterDate = []
            filtersRecords.filtersRecordRegisterDate = []
            this.date.filtersRegisterDate = []
            filtersRecords.filtersRegisterDate = []
            this.filters['sent_date_time'] = null
            this.filtersObjects.sent_date_time = null
            filtersRecords.sent_date_time = null
          }

          if (tag === 'stringFilter') {
            this.filters[tag] = null
            this.filtersObjects[tag] = null
            filtersRecords.filtersObjects[tag] = null
          }
        } else {
          filtersRecords.structures.structureFilter = _.filter(filtersRecords.structures.structureFilter, (s) => {
            return s.id !== tag
          })
          filtersRecords.structures.structuresByIds = _.filter(filtersRecords.structures.structuresByIds, (s) => {
            return s.structure_id !== tag
          })

          delete this.data[tag]
        }
        localStorage.setItem('filtersRecords', JSON.stringify(filtersRecords))
        this.applyFilters()
        this.filteredChips()
        this.getCantFilters()
      },

      dateRangeText() {
        let [year, month, day] = '-'
        let [year2, month2, day2] = '-'
        if (this.date.filtersRecordDate.length > 1) {
          if (this.date.filtersRecordDate[0]) {
            ;[year, month, day] = this.date.filtersRecordDate[0].split('-')
            this.filters.start_record_date_time = moment(this.date.filtersRecordDate[0]).format('YYYY-MM-DD') + 'T00:00:01'
          }
          if (this.date.filtersRecordDate[1]) {
            ;[year2, month2, day2] = this.date.filtersRecordDate[1].split('-')
            this.filters.end_record_date_time = moment(this.date.filtersRecordDate[1]).format('YYYY-MM-DD') + 'T23:59:59'
            this.filters.start_record_date_time = [
              this.filters.start_record_date_time,
              this.filters.end_record_date_time,
            ]
          }
          this.date.filtersDate = `${day}/${month}/${year} ~ ${day2}/${month2}/${year2}`
        }
      },

      dateSendRangeText() {
        let [year, month, day] = '-'
        let [year2, month2, day2] = '-'
        if (this.date.filtersRecordRegisterDate.length > 1) {
          this.date.filtersRecordDate = `${day}/${month}/${year} ~ ${day2}/${month2}/${year2}`
        }
      },

      deconstructRangeTextForFilters(dates, filter, field) {
        let [year, month, day] = '-'
        let [year2, month2, day2] = '-'
        if (dates.length > 1) {
          if (dates[0]) {
            ;[year, month, day] = dates[0].split('-')
            this.filters[filter][0] = moment(dates[0]).format('YYYY-MM-DD') + 'T00:00:01';
          }
          if (dates[1]) {
            ;[year2, month2, day2] = dates[1].split('-')
            this.filters[filter][1] = moment(dates[1]).format('YYYY-MM-DD') + 'T23:59:59';
          }
          this.date[field] = `${day}/${month}/${year} ~ ${day2}/${month2}/${year2}`
        }
      },


      filtered(key) {
        let records = key
          ? _.filter(this.recordsByCompany, record => {
              return record.process.sdt_to_show.id === key
            })
          : this.recordsByCompany
        records = records.filter(d => {
          return Object.keys(this.filters).every(f => {
            const range1 = moment(this.filters[f][0], 'YYYY-MM-DD').format(
              'YYYY-MM-DD'
            )
            const range2 = moment(this.filters[f][1], 'YYYY-MM-DD').format(
              'YYYY-MM-DD'
            )
            const target = moment(d[f], 'YYYY-MM-DD').format('YYYY-MM-DD')
            return (
              this.filters[f].length < 1 ||
              this.filters[f].includes(d[f]) ||
              (moment(range1).isSameOrBefore(target, 'day') &&
                moment(range2).isSameOrAfter(target, 'day'))
            )
          })
        })

        if (this.structureFilter.length > 0) {
          records = _.filter(records, o => {
            return (
              _.filter(o.recordStructureValues, dto => {
                let structureValues = _.find(this.structureFilter, {
                  structure_id: dto.structure_id,
                })
                structureValues = structureValues
                  ? structureValues.structure_values
                  : []
                return _.includes(structureValues, dto.record_value)
              }).length > 0
            )
          })
        }
        return records
      },

      async applyFilters() {
        let filtersRecords = JSON.parse(localStorage.getItem('filtersRecords'))

        this.filters.structure = []
        if (filtersRecords) {
          if (
            filtersRecords.structures.structuresByIds &&
            filtersRecords.structures.structuresByIds.length > 0
          ) {
            _.forEach(this.structuresByIds, structure => {
              if (
                ['DATA', 'TIME', 'DATA_TIME'].includes(structure.structure_type)
              ) {
                _.forEach(structure.structures_selected, filter => {
                  this.filters.structure.push(new Date(filter).toISOString())
                })
              } else {
                _.forEach(structure.structures_selected, filter => {
                  this.filters.structure.push(filter)
                })
              }
            })
          }

          let structures = [];

          _.map((this.data), (d) => {
            structures.push(...d);
          })

          this.filters.structure = [...structures];

          this.filters.stringFilter = filtersRecords.filtersObjects.stringFilter && this.filtersObjects.stringFilter !== ''
            ? this.filtersObjects.stringFilter
            : null

          this.structureFilter =
            filtersRecords.structures.structureFilter &&
            filtersRecords.structures.structureFilter.length > 0
              ? filtersRecords.structures.structureFilter
              : []
          this.structuresByIds =
            filtersRecords.structures.structuresByIds &&
            filtersRecords.structures.structuresByIds.length > 0
              ? filtersRecords.structures.structuresByIds
              : []
          this.filters.structureIds =
              filtersRecords.structures.structuresByIds &&
              filtersRecords.structures.structuresByIds.length > 0
                  ? _.map(filtersRecords.structures.structuresByIds, (structureValue) => { return structureValue.structure_id })
                  : []

          this.filters.person_in_charge_ids =
            filtersRecords.filtersObjects.person_in_charge_ids &&
            filtersRecords.filtersObjects.person_in_charge_ids.length > 0
              ? this.getByProperty(
                  filtersRecords.filtersObjects.person_in_charge_ids,
                  'id'
                )
              : []
          this.filters.process_ids =
            filtersRecords.filtersObjects.process_ids &&
            filtersRecords.filtersObjects.process_ids.length > 0
              ? this.getByProperty(
                  filtersRecords.filtersObjects.process_ids,
                  'id'
                )
              : []

          this.filters.status = filtersRecords.filtersObjects.record.status &&
          filtersRecords.filtersObjects.record.status.length > 0
              ? filtersRecords.filtersObjects.record.status
              : []

          this.filters.products_ids =
            filtersRecords.filtersObjects.products_ids &&
            filtersRecords.filtersObjects.products_ids.length > 0
              ? this.getByProperty(
                  filtersRecords.filtersObjects.products_ids,
                  'product_name'
                )
              : []
          this.filters.lots =
            filtersRecords.filtersObjects.lots &&
            filtersRecords.filtersObjects.lots.length > 0
              ? _.map(filtersRecords.filtersObjects.lots, l => l.name)
              : []
          this.filters.seasons_ids =
            filtersRecords.filtersObjects.seasons_ids &&
            filtersRecords.filtersObjects.seasons_ids.length > 0
              ? this.getByProperty(
                  filtersRecords.filtersObjects.seasons_ids,
                  'season_name'
                )
              : []
          this.filters.sdi_destination_ids =
            filtersRecords.filtersObjects.sdi_destination_ids &&
            filtersRecords.filtersObjects.sdi_destination_ids.length > 0
              ? getByProperty(filtersRecords.filtersObjects.sdi_destination_ids, 'storageDeviceId')
              : []
          this.filters.sdi_origin_ids =
            filtersRecords.filtersObjects.sdi_origin_ids &&
            filtersRecords.filtersObjects.sdi_origin_ids.length > 0
              ? getByProperty(filtersRecords.filtersObjects.sdi_origin_ids, 'storageDeviceId')
              : []

          this.filters.start_record_date_time =
            filtersRecords.filtersRecordDate.length > 0
              ? [
                  moment(filtersRecords.filtersRecordDate[0]).format(
                    'YYYY-MM-DD'
                  ) + 'T00:00:01',
                  moment(filtersRecords.filtersRecordDate[1]).format(
                    'YYYY-MM-DD'
                  ) + 'T23:59:59',
                ]
              : []

          this.filters.sent_date_time =
            filtersRecords.filtersRecordRegisterDate.length > 0
              ? [
                  moment(filtersRecords.filtersRecordRegisterDate[0]).format(
                    'YYYY-MM-DD'
                  ) + 'T00:00:01',
                  moment(filtersRecords.filtersRecordRegisterDate[1]).format(
                    'YYYY-MM-DD'
                  ) + 'T23:59:59',
                ]
              : []
        }

        localStorage.setItem('filtersRecords', JSON.stringify(filtersRecords))
        await this.getRecordsPaginatedByCompany()
      },
      // Fetch Records
      async getRecordsPaginatedByCompany() {
        this.loading = true

        this.request = true
        const CancelToken = axios.CancelToken
        this.source = CancelToken.source()
        this.message = i18n.t('general.notification.loadData')

        const sort = this.options.sortBy.length > 0 ? this.options.sortBy[0] : 'record_date_time';

        const direction =
          this.options.sortDesc.length === 0 || this.options.sortDesc[0]
            ? 'desc'
            : 'asc'

        this.filters.pageableDTO = {
          page: this.options.page - 1,
          size: this.options.itemsPerPage,
          sortBy: sort,
          direction: direction,
        }

        if (this.sdtTab !== this.filterRecord.stageId) {
          this.options.page = 1
        }
        this.optionsTable = this.options

        this.sdtTab = _.cloneDeep(this.filterRecord.stageId)

        this.filters.storage_device_types_ids = [this.filterRecord.stageId]

        this.filters.company_id = this.profile.company_id

        const keys = [
          'quebra_range',
          'sellers_names',
          'contracts_names',
          'contracts_dates',
          'volumeB_range',
          'volumeN_range',
          'surveyStateType',
          'template_names',
          'min_punctuation',
          'max_punctuation',
          'storage_devices_ids',
          'document_authority_ids',
          'document_type_ids',
          'document_description_ids',
          'document_date',
          'expiration_date',
        ]
        let filters = _.omit(this.filters, keys)
        filters.language = LanguajeService.getLenguajeName()

        if (this.search && this.search !== '')
          filters.stringFilter = this.search

        console.log(filters)

        if (this.$refs.tableExtraColumns)
          await this.$refs.tableExtraColumns[0].cancelDialogConfig();
        else
          await this.$store.dispatch('authority/loadConfigUser', [this.$toast])

        //------------------------------------------
        const config = JSON.parse(localStorage.getItem('config'))

        if (
          config &&
          config.structures &&
          Object.keys(config.structures.byGroup).length > 0
        ) {
          this.config.headers = _.cloneDeep(this.defaultHeaders)

          const stage = _.find(this.storageDeviceTypesActiveByCompany, {
            storage_device_type_id: this.filterRecord.stageId,
          })
          const id = stage.storage_device_type_id

          if (
            config.structures.byGroup[id] &&
            config.structures.byGroup[id].processed.length > 0
          ) {
            _.forEach(config.structures.byGroup[id].processed, header => {
              this.config.headers.splice(
                this.config.headers.length - 1,
                0,
                header
              )
            })

            this.headers = _.cloneDeep(this.config.headers)
          } else {
            this.headers = _.cloneDeep(this.defaultHeaders)
          }
        } else {
          this.headers = _.cloneDeep(this.defaultHeaders)
        }

        //------------------------------------------

        await this.fetchListRecordsByCompanyPaginated([
          filters,
          this.source,
          this.$toast,
        ]).finally(() => {
          this.$root.$emit('updateOptions', null)
          this.loading = false
          this.$emit('isDisable', this.loading)
        })
      },
      getCantFilters() {
        let cant = 0
        _.forEach(this.filters, (value, key) => {
          if (![...FILTER_VALUES, 'stringFilter'].includes(key) && (value !== null)) {
            cant +=
              key === 'end_record_date_time' ||
              key === ' start_record_date_time' ||
              key === ' sent_date_time'
                ? 1
                : value.length
          }

          cant +=
            (key === 'stringFilter' && value && value !== '')
              ? 1
              : 0
        })
        _.forEach(this.structuresByIds, structure => {
          cant += structure.structures_selected.length
        })
        this.cantFilters = cant
      },

      filteredChips() {
        let chips = []
        _.forEach(this.filters, (value, key) => {
          if (
            ![...FILTER_VALUES, 'stringFilter'].includes(key) &&
            this.filters[key] !== null &&
            this.filters[key].length > 0
          ) {
            chips.push({
              value: key,
              name: _.filter(this.filterHeader, { val: key })[0].text,
            })
          } else {
            if (key === 'stringFilter' && value && value !== '') {
              chips.push({
                value: key,
                name: i18n.t("general.fields.text"),
              })
            }
          }
        })

        _.forEach(this.structureFilter, structure => {
          chips.push({
            value: structure.id,
            name: structure.structure_name,
          })
        })

        this.filtersChips = chips
      },
      closeFilterDialog() {
        this.search = ''
        this.applyFilters()
        this.filteredChips()
        this.getCantFilters()
        this.drawer = false
      },
      getByProperty(array, property) {
        const allAreObjectsWithProperty = array.every(item =>
          typeof item === 'object' && item !== null && Object.hasOwnProperty.call(item, property)
        );

        if (allAreObjectsWithProperty) {
          return _.map(array, property);
        } else {
          return array;
        }
      },
      replace(event) {
        event.currentTarget.value = event.currentTarget.value
          .toString()
          .replaceAll('"', '')
        this.search = event.currentTarget.value.toString().replaceAll('"', '')
      },
    },

    beforeDestroy() {
      this.type = null;
      console.log('Records view destroyed');
    },

    destroyed() {
      this.request = false
      this.message = i18n.t('general.notification.loadData')
      if (this.source) {
        this.source.cancel('cancelRequest')
        this.source = null
      }

      this.$root.$off('setFiltersRecords')
      this.$root.$off('getResultsPaginatedByCompany')
      this.$store.commit('ui/FILTERS_OBJECTS_ORIGINAL_STATE');
    },
  }
</script>

<style scoped>
  .v-menu__content {
    border-radius: .5em;
    box-shadow: rgba(0, 0, 0, 0.1) 0px 4px 12px !important;
    margin-top: 10px;
  }
  .scroll-area {
    position: relative;
    margin: auto;
    width: 600px;
    height: 400px;
  }
  .table__head {
    height: 5vh;
    background-color: #f7f7f7;
  }
  :deep(.v-chip-group .v-slide-group__content) {
    display: flex !important;
    justify-content: flex-start !important;
  }
  :deep(.v-toolbar__content) {
     padding: 0 !important;
   }
</style>
