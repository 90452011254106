<template>
  <v-dialog v-model='dialog' persistent width='600px'>
    <template v-slot:activator='{ on, attrs }'>
      <v-btn text @click='openDialog()'>
        <v-icon color='gray' left small>mdi-upload-multiple</v-icon>
        EXCEL
      </v-btn>
    </template>
    <v-card>
      <v-card-title class='headline'>
        {{ $t('reports.titles.checkBurdens') }}
        <v-spacer />
      </v-card-title>
      <v-card-text>
        <v-form ref="form" v-model="valid">
          <v-row>
            <v-col cols="12">
              <v-alert
                class="mt-2"
                border="left"
                color="orange"
                dense
                text
                type="warning"
                icon="mdi-alert-outline"
              >
                {{ $t("reports.notification.warningCheckBurdens") }}
              </v-alert>
            </v-col>
            <v-col cols='12'>
              <v-file-input
                v-model='file'
                :label="$t('general.buttons.upload_doc')"
                :rules='requireRulesSizeAndType(file).concat(required)'
                append-icon='mdi-file-excel'
                color='secondary'
                prepend-icon=''
              >
              </v-file-input>
            </v-col>
          </v-row>
        </v-form>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn
          :disabled='loading'
          color='gray'
          small
          text
          @click='dialogCheckBurdensClose'
        >
          {{ $t('general.buttons.cancel') }}
        </v-btn>
        <v-btn
          :disabled='loading || !valid'
          :loading='loading'
          color='secondary'
          small
          text
          @click='checkBurdensAction()'
        >
          {{ $t('general.buttons.confirm') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapActions, mapGetters } from 'vuex'

  export default {
    name: 'CheckBurdentsComponent',

    props: {},

    data: () => ({
      dialog: false,
      loading: false,
      valid: false,
      file: null,
      errors: [],
      count: {
        success: null,
        errors: null,
        total: null
      }
    }),

    computed: {
      ...mapGetters({
        requireRulesSizeAndType: 'general/requireRulesSizeAndType',
        required: 'general/requireRules',
      }),
    },

    methods: {
      ...mapActions({
        checkBurdens: 'records/checkBurdens',
      }),
      openDialog() {
        this.$emit('closeMenu', false)
        this.dialog = true
      },
      async checkBurdensAction() {
        this.loading = true

        await this.checkBurdens([{
          file: this.file,
        }, this.$toast])
        .then((res) => {
          this.dialogCheckBurdensClose()
        })
      },
      dialogCheckBurdensClose() {
        this.dialog = false
        this.file = null
        this.loading = false
        this.$refs.form.reset()
      },
    },
  }
</script>

<style scoped>
.v-chip {
  border-radius: .2em !important;
}
</style>