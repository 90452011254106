import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VContainer,{attrs:{"fluid":""}},[_c(VListItem,{attrs:{"three-line":""}},[_c(VListItemContent,[_c('p',{staticClass:"display-1 text--primary"},[_vm._v(" "+_vm._s(_vm.$t('menu.appointments'))+" ")])])],1),_c(VTabs,{attrs:{"center-active":"","slider-size":3,"color":"secondary","item-key":"name","value":_vm.tabIndex,"show-arrows":""},model:{value:(_vm.tabIndex),callback:function ($$v) {_vm.tabIndex=$$v},expression:"tabIndex"}},_vm._l((_vm.storageDeviceTypesActiveByCompany),function(storageTypeTab,index){return _c(VTab,{key:index,attrs:{"disabled":_vm.isDisable},on:{"click":function($event){return _vm.getDataByFilters($event, storageTypeTab)}}},[_c(VImg,{staticClass:"mb-1 mr-2",attrs:{"src":storageTypeTab.iconBase64,"max-height":"30","min-height":"30","max-width":"30","min-width":"30","eager":""}}),_vm._v(" "+_vm._s(storageTypeTab.storage_type_name)+" ")],1)}),1),(_vm.storageDeviceTypesActiveByCompany && _vm.storageDeviceTypesActiveByCompany.length > 0)?_c('record-table',{attrs:{"stage":_vm.stage},on:{"isDisable":function($event){return _vm.isDisableTabs($event)}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }