import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render(){var _vm=this,_c=_vm._self._c;return _c(VDialog,{attrs:{"persistent":"","width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c(VBtn,{attrs:{"text":"","disabled":_vm.disable},on:{"click":_vm.exportDialogCSV}},[_c(VIcon,{attrs:{"left":"","small":"","color":"gray"}},[_vm._v("mdi-download-multiple")]),_vm._v(" CSV ")],1)]}}]),model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c(VCard,[_c(VCardTitle,{staticClass:"headline"},[_vm._v(" "+_vm._s(_vm.$t('reports.titles.exportConfigCSV'))+" "),_c(VSpacer),(_vm.number)?_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.number)+" "+_vm._s(_vm.$t('records.label.titles.recordsFound'))+" ")]):(_vm.number === 0)?_c('span',{staticStyle:{"font-size":"13px"}},[_vm._v(" "+_vm._s(_vm.$t('records.label.titles.notRecordsFound'))+" ")]):_vm._e()],1),_c(VCardText,[_c(VRow,[_c(VCol,{attrs:{"cols":"12"}},[_c(VAutocomplete,{staticClass:"d-flex align-baseline",attrs:{"no-data-text":_vm.$t('general.fields.noDataAvailable'),"color":"secondary","label":_vm.$t('records.fields.process'),"clearable":"","item-color":"secondary","items":_vm.allProcess,"item-text":"proccess_name","item-value":"proccess_name","return-object":"","small-chips":"","loading":_vm.loadings.process,"disabled":_vm.loadings.process || _vm.loadings.export},on:{"change":function($event){return _vm.filterByProcessCSV($event)}},scopedSlots:_vm._u([{key:"item",fn:function({ item }){return [[_c(VListItemContent,{domProps:{"textContent":_vm._s(item.proccess_name)}})]]}},{key:"selection",fn:function({ item, index }){return [_c(VChip,{attrs:{"small":""}},[_c('span',[_vm._v(_vm._s(item.proccess_name))])])]}},{key:"prepend-item",fn:function(){return [(
                      _vm.filtersObjects &&
                      _vm.filtersObjects.process_ids &&
                      _vm.filtersObjects.process_ids.length > 1
                    )?_c(VListItem,[_c(VListItemContent,{staticClass:"py-0"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-6 py-0 text-right"},[_c(VBtn,{attrs:{"large":"","text":"","color":"secondary","disabled":_vm.loadings.process || _vm.loadings.export},on:{"click":function($event){return _vm.seeProcess('all')}}},[_vm._v(" "+_vm._s(_vm.$t('records.buttoms.show_all'))+" ")])],1),_c(VDivider,{attrs:{"vertical":""}}),_c('div',{staticClass:"col-5 py-0 text-left"},[_c(VBtn,{attrs:{"large":"","text":"","color":"secondary","disabled":_vm.loadings.process || _vm.loadings.export},on:{"click":function($event){return _vm.seeProcess('selected')}}},[_vm._v(" "+_vm._s(_vm.$t('records.buttoms.by_filtered'))+" ")])],1)],1)])],1):_vm._e(),_c(VDivider,{staticClass:"mb-2"})]},proxy:true}]),model:{value:(_vm.processCSVExport),callback:function ($$v) {_vm.processCSVExport=$$v},expression:"processCSVExport"}})],1)],1)],1),_c(VCardActions,[_c(VSpacer),_c(VBtn,{attrs:{"small":"","text":"","color":"gray"},on:{"click":_vm.dialogExportCSVClose}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.cancel'))+" ")]),_c(VBtn,{attrs:{"small":"","text":"","color":"secondary","disabled":_vm.loadings.export || !_vm.processCSVExport,"loading":_vm.loadings.export},on:{"click":function($event){return _vm.exportCSV(_vm.processCSVExport)}}},[_vm._v(" "+_vm._s(_vm.$t('general.buttons.confirm'))+" ")])],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }